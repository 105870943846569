import { findFirstNonEmptyString } from "@product/scmp-sdk";
import { type FunctionComponent, startTransition, useMemo, useRef, useState } from "react";
import { graphql, useFragment } from "react-relay";

import { useTopicType } from "scmp-app/components/topic/hooks";
import { formatInTimeZone } from "scmp-app/lib/utils";
import type { topicDescriptionTopic$key } from "scmp-app/queries/__generated__/topicDescriptionTopic.graphql";

import {
  ChineseTitle,
  Container,
  Description,
  DescriptionContainer,
  Divider,
  ExpandContainer,
  ImageIcon,
  ShowButton,
  Title,
  TitleContainer,
  TitleRow,
  UpdatedDateContainer,
  UpdatedDateDescription,
  UpdatedDateTitle,
} from "./styles";
import { TopicMetadataDisplay } from "./topic-metadata-display";

type Props = {
  className?: string;
  reference: topicDescriptionTopic$key;
};

export const TopicDescription: FunctionComponent<Props> = ({
  className,
  reference: reference_,
}) => {
  const topic = useFragment(
    graphql`
      fragment topicDescriptionTopic on Topic {
        ...topicHeaderRelatedLinksTopic
        ...entityFollowButtonBase
        ...hooksTopic
        ...topicMetadataDisplayTopic
        extraMetadata {
          __typename
        }
        mobileImage: image(type: MOBILE_APP_IMAGE) {
          alt
          title
          style(filter: { style: "300x300" }) {
            url
          }
        }
        name
        description {
          text
        }
        chineseName
        updatedDate
      }
    `,
    reference_,
  );

  const { currentType, hasSponsor } = useTopicType(topic);
  const isGeneralTopic = currentType === "general";
  const isShowImageIcon = !isGeneralTopic && !hasSponsor;

  const [isShowFullProfile, setIsShowFullProfile] = useState(false);
  const isHavingMoreContent = useMemo(() => {
    const textLength = topic?.description?.text?.length ?? 0;
    const extraMetadataLength = topic?.extraMetadata?.length ?? 0;
    return extraMetadataLength > 0 || textLength > 100;
  }, [topic?.description?.text?.length, topic?.extraMetadata?.length]);

  const containerReference = useRef<HTMLDivElement>(null);

  const description = useMemo(
    () =>
      findFirstNonEmptyString(
        topic.description?.text,
        `The latest news, analysis and opinion on ${topic.name}. In-depth analysis, industry insights and expert opinion.`,
      ),
    [topic.description?.text, topic.name],
  );

  return (
    <Container className={className} ref={containerReference}>
      <TitleContainer>
        {isShowImageIcon && topic.mobileImage?.style?.url && (
          <ImageIcon
            $aspectRatio="1"
            alt={topic.mobileImage.alt ?? topic.mobileImage.title ?? ""}
            lazyload={false}
            src={topic.mobileImage.style.url}
          />
        )}
        <TitleRow>
          <Title>{topic.name}</Title>
          {topic.chineseName && <ChineseTitle>{topic.chineseName}</ChineseTitle>}
        </TitleRow>
      </TitleContainer>
      <DescriptionContainer
        $isHavingMoreContent={isHavingMoreContent}
        $isShowFullProfile={isShowFullProfile}
      >
        <Description>{description}</Description>
        <ShowButton
          onClick={() => {
            setIsShowFullProfile(true);
            startTransition(() => {
              setTimeout(() => {
                const elementPosition =
                  containerReference.current?.getBoundingClientRect().top ?? 0;
                const scrollPosition = document?.scrollingElement?.scrollTop ?? 0;

                document.scrollingElement?.scrollTo({
                  behavior: "smooth",
                  top: scrollPosition + elementPosition - 45,
                });
              }, 150);
            });
          }}
        >
          SHOW FULL PROFILE
        </ShowButton>
        <ExpandContainer>
          <TopicMetadataDisplay reference={topic} />
          {topic.updatedDate && (
            <>
              <Divider />
              <UpdatedDateContainer>
                <UpdatedDateTitle>last updated: </UpdatedDateTitle>
                <UpdatedDateDescription>
                  {formatInTimeZone(topic.updatedDate, "d MMMM, yyyy")}
                </UpdatedDateDescription>
              </UpdatedDateContainer>
            </>
          )}
        </ExpandContainer>
      </DescriptionContainer>
    </Container>
  );
};

TopicDescription.displayName = "TopicDescription";
