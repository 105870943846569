import styled from "@emotion/styled";
import { fontRobotoCondensed, transientOptions } from "@product/scmp-sdk";

import { BaseImage } from "scmp-app/components/common/base-image";
import { BaseLink } from "scmp-app/components/common/base-link";

export const Container = styled.div``;

export const Divider = styled.div`
  margin-block: 16px 16px;
  border-block-start: 1px solid #bbbbbb;
`;

export const ExtraMetadataContainer = styled.div`
  display: grid;
  gap: 16px;

  ${props => props.theme.breakpoints.between("tablet", "desktop")} {
    grid-template-columns: 1fr 1fr;
  }
`;

export const ExtraMetadataItem = styled.div`
  display: flex;
  gap: 4px;
  justify-content: space-between;
  align-items: center;

  font-size: 14px;
  line-height: 140%;
`;

export const ExtraMetadataTitle = styled.div`
  color: #000000;
  font-weight: 700;
  font-size: 12px;
  font-family: ${fontRobotoCondensed};
  line-height: 14.06px;
  text-align: start;
  text-transform: uppercase;
`;

export const ExtraMetadataDescription = styled.div`
  color: #666666;
  font-weight: 400;
  font-size: 14px;
  font-size: 16px;
  font-family: ${fontRobotoCondensed};
  line-height: 20.8px;
  text-align: start;
`;

export const StyledBaseLink = styled(BaseLink, { ...transientOptions })`
  display: flex;
  align-items: center;

  color: #4585ff;
  font-weight: 400;
  font-size: 16px;
  font-family: ${fontRobotoCondensed};
  line-height: 20.8px;
  text-align: start;
  text-decoration: underline;
`;

export const ImageIcon = styled(BaseImage)`
  inline-size: 48px;
  block-size: 48px;

  border: 1px solid #bbbbbb;
  border-radius: 50%;

  object-fit: cover;
  object-position: 50%;
`;

export const ExtraMetadataRow = styled.div`
  display: grid;
  gap: 4px;
  align-items: center;
`;
