import { Fragment, type ReactNode, useCallback } from "react";
import { graphql, readInlineData } from "react-relay";

import { HundredTopTablesDiscoveryWidget } from "scmp-app/components/hundred-top-tables-discovery-widget";
import { PostMagazineDiscoveryWidget } from "scmp-app/components/post-magazine/discovery-widget";
import { ChinaScienceDiscoveryModule } from "scmp-app/components/rhs-module/china-science-discovery-module";
import { OpenQuestionsSeriesDiscoveryModule } from "scmp-app/components/rhs-module/open-questions-series-discovery-module";
import { AiArticleWidget } from "scmp-app/components/section/ai-article-widget";
import { TopicAds } from "scmp-app/components/topic/topic-ads";
import { config } from "scmp-app/data";
import { AiEngineDataType } from "scmp-app/lib/ai-engine/enums";
import { RHSModule2, RHSModule3 } from "scmp-app/pages/topic/styles";
import type { hooksTopicWidgetQuery$key } from "scmp-app/queries/__generated__/hooksTopicWidgetQuery.graphql";

import { TopicIdsShowing100TopTablesDiscoveryWidget } from "./consts";
import type { TopicConfig, TopicWidget } from "./types";

type UseTopicsAndAdsWidgetsProperty = {
  contentItemLength: number;
  hasSponsor: boolean;
  reference: hooksTopicWidgetQuery$key;
};
export const useTopicsAndAdsWidgets = ({
  contentItemLength,
  hasSponsor,
  reference,
}: UseTopicsAndAdsWidgetsProperty) => {
  const data = readInlineData(
    graphql`
      fragment hooksTopicWidgetQuery on Query
      @inline
      @argumentDefinitions(entityId: { type: "String!" }) {
        topic(filter: { entityId: $entityId }) {
          entityId
          ...hooksTopic
          ...topicAdsTopic
          ...topicMetadataDisplayTopic
        }
        ...discoveryWidgetPostMagazineQueueQuery
        ...chinaScienceDiscoveryModuleQueueQuery
        ...hundredTopTablesDiscoveryWidgetQuery
        ...openQuestionsSeriesDiscoveryModuleQuery
          @arguments(scmpPlusPaywallTypeIds: $scmpPlusPaywallTypeIds)
        appConfig(filter: { entityId: "scmp_pwa_widget" }) {
          json
        }
      }
    `,
    reference,
  );

  const getSpecificWidgets = useCallback(() => {
    const appConfig = data.appConfig?.json as TopicConfig;
    const specificWidgets =
      config.general.env === "production" ? appConfig.production : appConfig.dev;

    if (specificWidgets?.topic_rhs_modules) {
      for (const [type, entityIds] of Object.entries(specificWidgets.topic_rhs_modules)) {
        if (entityIds.includes(data.topic.entityId)) {
          return type as TopicWidget;
        }
      }
    }
    return null;
  }, [data.appConfig?.json, data.topic.entityId]);

  const specificWidgetType = getSpecificWidgets();
  const isShowSpecificWidget = specificWidgetType !== null;
  const renderRecommendedForYou = () => (
    <AiArticleWidget aiEngineDatatype={AiEngineDataType.HomeRecommendedForYou} />
  );

  const renderPostMagazine = () => <PostMagazineDiscoveryWidget reference={data} />;
  const renderChinaScienceDiscovery = () => <ChinaScienceDiscoveryModule reference={data} />;
  const renderOpenQuestionsSeriesDiscoveryModule = () => (
    <OpenQuestionsSeriesDiscoveryModule reference={data} />
  );
  const render100TopTables = () => <HundredTopTablesDiscoveryWidget reference={data} />;

  const rhsModuleComponents = [RHSModule2, RHSModule3];

  const getTopicWidgets = () => {
    if (TopicIdsShowing100TopTablesDiscoveryWidget.has(data.topic.entityId)) {
      return ["100-top-tables", "recommended-for-you"] as TopicWidget[];
    }
    if (specificWidgetType !== null) {
      return [specificWidgetType, "recommended-for-you"] as TopicWidget[];
    }
    return ["recommended-for-you"] as TopicWidget[];
  };

  const topicWidgetComponentMap: Record<TopicWidget, () => ReactNode> = {
    "100-top-tables": render100TopTables,
    "china-science-discovery": renderChinaScienceDiscovery,
    "open-questions-series-discovery": renderOpenQuestionsSeriesDiscoveryModule,
    "post-magazine": renderPostMagazine,
    "recommended-for-you": renderRecommendedForYou,
  };

  const modules = getTopicWidgets();
  const selectedModules = hasSponsor ? modules.slice(0, 1) : modules;
  const renderModules = selectedModules.map(widget => topicWidgetComponentMap[widget]);

  const getMaxAdsNumber = (edgesLength: number, hasSponsor = false) => {
    const adsNumbers = selectedModules.length > 2 ? [26, 23, 15] : [26, 18, 15];
    const totalAdsNumbers = hasSponsor ? [26, 18, 11] : adsNumbers;
    const showAdsNumbers = [4, 3, 2];
    for (const [index, totalAdsNumber] of totalAdsNumbers.entries()) {
      if (edgesLength >= totalAdsNumber) {
        return showAdsNumbers[index];
      }
    }
    return 1;
  };

  const renderTopicAds = () => (
    <TopicAds
      isShowSpecificWidget={isShowSpecificWidget}
      maxAdsNumber={getMaxAdsNumber(contentItemLength, hasSponsor)}
      reference={data.topic}
    />
  );

  const widgets = renderModules.map((module, index) => {
    const Wrapper = rhsModuleComponents[index];
    const displayModuleCount = [15, 21];

    if (!(displayModuleCount[index] < contentItemLength)) return null;

    return (
      <Fragment key={index}>
        <Wrapper>{module?.()}</Wrapper>
      </Fragment>
    );
  });

  return { isShowSpecificWidget, render: { ads: renderTopicAds(), widgets } };
};
