import { theme } from "@product/scmp-sdk";
import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";
import type { FragmentRefs } from "relay-runtime";

import { AdSlot } from "scmp-app/components/advertisement/ad-slots/ad-slot";
import { TargetingPaidType } from "scmp-app/components/advertisement/ad-slots/types";
import type { topicTopContentsTopic$key } from "scmp-app/queries/__generated__/topicTopContentsTopic.graphql";

import {
  AdsSlotContainer,
  StyledTopStoriesItem,
  TopContentsContainer,
  TopContentsLeftContainer,
  TopContentsRightContainer,
} from "./styles";

type Props = {
  index: number;
  reference: topicTopContentsTopic$key;
  topContents: {
    content: {
      readonly " $fragmentSpreads": FragmentRefs<
        | "homeSecondaryContentItemContent"
        | "sectionTopLeadPrimaryContentItemContent"
        | "topStoriesItemContent"
      >;
    }[];
    type: string;
  };
};

export const TopicTopContents: FunctionComponent<Props> = ({ index, reference, topContents }) => {
  const data = useFragment(
    graphql`
      fragment topicTopContentsTopic on Topic {
        entityId
        advertZone(version: 2)
      }
    `,
    reference,
  );

  if (!topContents.content) {
    return null;
  }
  const [first, second, ...rest] = topContents.content;
  const isShowAds = (index + 1) % 3 === 1; // Show ads in every first block

  const renderLastItemAd = () => {
    if (!isShowAds) return null;
    return (
      <AdsSlotContainer>
        <AdSlot
          adUnit={index === 0 ? "d_native2a" : "d_native2c"}
          breakpoint={theme.breakpoints.up("tablet")}
          customizedAdUnitTargeting={index === 0 ? "dnative-7a" : "dnative-7b"}
          sizes={[[1, 1], "fluid"]}
          targeting={{
            paid: TargetingPaidType.Free,
            sctid: data?.entityId,
          }}
          zone={data?.advertZone ?? "default"}
        />
        <AdSlot
          adUnit={index === 0 ? "m_native2a" : "m_native2c"}
          breakpoint={theme.breakpoints.only("mobile")}
          customizedAdUnitTargeting={index === 0 ? "dnative-7a" : "dnative-7b"}
          sizes={[[1, 1], "fluid"]}
          targeting={{
            paid: TargetingPaidType.Free,
            sctid: data?.entityId,
          }}
          zone={data?.advertZone ?? "default"}
        />
      </AdsSlotContainer>
    );
  };

  return (
    <TopContentsContainer>
      <TopContentsLeftContainer>
        {first && (
          <StyledTopStoriesItem
            onClick={() => {}}
            reference={first}
            variant="primary"
            withCaption={false}
            withSection={false}
          />
        )}
        {second && (
          <StyledTopStoriesItem
            onClick={() => {}}
            reference={second}
            variant="secondary"
            withSection={false}
          />
        )}
      </TopContentsLeftContainer>
      <TopContentsRightContainer>
        {rest.map((item, itemIndex) => (
          <StyledTopStoriesItem
            isShowImage={!isShowAds && itemIndex === rest.length - 1}
            key={itemIndex}
            onClick={() => {}}
            reference={item}
            variant="secondary"
            withSection={false}
          />
        ))}
        {renderLastItemAd()}
      </TopContentsRightContainer>
    </TopContentsContainer>
  );
};

TopicTopContents.displayName = "TopicTopContents";
